import React, { Component, Fragment } from "react";
import * as FORM from "../components/FormComponents";
import { GetLocation, ReverseGeoLookup } from "../libs/locLib";
import "./Forms.css";
import J from "./staticform.js";
import axios from "axios";

import { s3Upload } from "../libs/awsLib";

export default class ExternalForm extends Component {
    b;
    Doc;
    constructor(props) {
        super(props);
        var self = this;
        this.state = {
            formdark: "#aa0000",
            formname: "...",
            formcaption: "Loading . . . ",
            formimagebackground: "Gray-50.png",
            formimage: null,
            formcontent: null,
            formcallback: null,
            FormImages: {},
            IsSending: false,
            errorState: false,
            IsSent: false,
            RunOnce: false,
            FormNotFound: "Form not found"
        };
        this.b = document.querySelector('body');
        this.b.className = "EXT";
        if (props.match.params.id && props.match.params.id.indexOf("%") > -1) {

            setTimeout(() => {
                const payload = props.match.params.id;
                var json2 = decodeURIComponent(payload);
                var json3 = JSON.parse(json2);
                this.getForm(this, json3);
            }, 1)
        } else if (props.match.params.id === "demo") {
            setTimeout(() => {
                this.getForm(this, J)
            }, 1)
        } else {
            setTimeout(() => {
                this.TrySTH(props.match.params.id, "gf", self.getForm)
            })
        }
    }

    componentDidUpdate() {
        if (this.props.match.params.add && this.props.match.params.add.indexOf("l") > -1) {
            if (!this.state.IsSending && !this.state.IsSent && !this.state.RunOnce) {
                this.setState({ RunOnce: true })
                GetLocation(this.callBackFunction, this);
            }
        }
    }
    callBackFunction(obj, self) {
        self.FillForm(obj.val.address);
        var place = { lat: obj.val.latitude, lng: obj.val.longitude };
        var map = new window.google.maps.Map(document.querySelector('.maps .map'), {
            center: place,
            disableDefaultUI: true,
            zoom: 14
        });
        var marker = new window.google.maps.Marker({ position: place, map: map, draggable: true });
        window.google.maps.event.addListener(marker, 'dragend', function (evt) {
            self.NewPlace(evt.latLng.lat(), evt.latLng.lng());
        });
    }
    async NewPlace(lat, lng) {
        var place = { lat: lat, lng: lng }
        var r = await ReverseGeoLookup(place);
        this.FillForm(r);
    }
    FillForm(loc) {
        for (let i = 0; i < this.state.formcontent.length; i++) {
            const el = this.state.formcontent[i].Elements;
            for (let k = 0; k < el.length; k++) {
                const element = el[k];
                if (element.SafeID.indexOf("Address") > -1) {
                    for (var index in loc) {
                        if ((element.SafeID.toLowerCase().indexOf("name") > -1) && index === "StreetAddress") {
                            document.getElementsByName(element.SafeID)[0].value = loc[index];
                        } else if ((element.SafeID.toLowerCase().indexOf(index.toLowerCase()) > -1) ||
                            (element.Caption.toLowerCase().indexOf(index.toLowerCase()) > -1)) {
                            var targetvalue = loc[index];
                            var htmlelement = document.getElementsByName(element.SafeID)[0];
                            if (element.Type === "ComboBox") {
                                this.FindOptionSelect(htmlelement, targetvalue);
                            } else {
                                document.getElementsByName(element.SafeID)[0].value = loc[index];
                            }
                        }
                    }
                }
            }

        }
    }
    FindOptionSelect(el, val) {
        var possibleVals = el.querySelectorAll('option');
        for (let i = 0; i < possibleVals.length; i++) {
            const element = possibleVals[i];
            if (element.innerText.toLowerCase().indexOf(val.toLowerCase()) > -1) {
                el.value = element.innerText;
                return;
            }
        }
        el.value = "";
    }

    async TrySTH(payload, action, callback) {
        var self = this;
        try {
            var config = {
                headers: {
                    'content-type': 'application/json',
                    'X-Api-Key': "4Pg2gsovsd1bsVDUBmGKj66Ptory8Qso4JZ6GOx8",
                }
            }
            var url = "https://nm67jjujef.execute-api.us-east-2.amazonaws.com/default/SendToHubstream";
            var data = {
                action: action,
                loc: self.props.match.params.id,
                key: self.props.match.params.env,
                data: payload
            }
            await axios.post(url, data, config)
                .then((d) => {
                    callback(self, d.data);
                })
        } catch (e) {
            this.setState({
                errorState: "Failed to connect to Hubstream",
                IsSent: true,
                IsSending: false
            })
        }
    }
    //*/
    tryStaticForm(st) {
        this.getForm(this, J)
    }
    FormFailed(self) {
        var o = {
            Name: self.state.FormNotFound,
            Background: "#550000",
            Front: "Gray-50.png",
            Logo: "error.png",
            Groups: [
                {
                    Elements: [
                        { Type: "TextBox", SafeID: "Email", Label: "Email" },
                        { Type: "ReadOnly", SafeID: "URLPath", Label: "Path", Value: self.props.match.url },
                        { Type: "MultiLineTextBox", SafeID: "Description", Label: "Which form were you looking for?" }
                    ]
                },
            ],
            key: "ASDF"
        }
        this.getForm(self, o);
    }
    getForm(self, R) {
        if (typeof R === "string") {
            self.FormFailed(self);
            return;
        }

        if (R.Name !== self.state.FormNotFound && self.props.match.params.add) {
            var imageIndex = self.props.match.params.add.indexOf('i');
            var charBefore = self.props.match.params.add.substr((imageIndex - 1), 1);
            if (imageIndex > -1) {
                if (charBefore && Number(charBefore)) {
                    R = self.AddImageField(R, charBefore);
                } else {
                    R = self.AddImageField(R);
                }

            }

        }
        self.setState({
            formdark: R.Background,
            formlight: "#ffffff",
            formimagebackground: R.Front,
            formname: R.Name,
            formcaption: R.Caption ? R.Caption : null,
            formimage: R.Logo,
            formcontent: R.Groups,
            formcallback: R.callback
        })
        self.b.style["background-color"] = R.Background;
        self.b.style["background-image"] = "url(/Images/" + R.Front + ")";
        //console.log(R.Background, R.Front, R.Name, R.Logo, R.Groups);
    }
    AddImageField(R, n) {
        var img_el = {
            DataType: "Text",
            ID: "test field",
            IsBulkEditEnabled: true,
            IsMultiColumn: true,
            IsRequired: false,
            MetadataID: 0,
            ParentMetadataID: 0,
            SafeID: "image_FORMFIELD",
            Type: "Image",
        }
        if (this.props.match.params.id === "reportafakeform") {
            img_el.Caption = "Please click here to upload any pictures such as products, packaging, storefront/signage, product purchase receipts, etc.";
        }
        if (n && n > 0) {
            R.Groups[n - 1].Elements.push(
                img_el
            )
        } else {
            R.Groups.push({
                Name: "Images",
                Elements: [
                    img_el
                ]
            })
        }
        return R;
    }

    validateForm() {
        return (
            this.state.email.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.confirmPassword
        );
    }

    handleChange = event => {
        var val;
        if (event.target.type === "checkbox") {
            val = event.target.checked;
        } else {
            val = event.target.value;
        }
        this.setState({
            [event.target.id]: val
        });

    }

    handleSubmit = async event => {
        var JSONPayload = {}
        event.preventDefault();
        var els = event.target.elements;
        for (let i = 0; i < els.length; i++) {
            const element = els[i];
            //console.log(element.name, element.value);
            if (element.type === "radio") {
                if (element.checked) {
                    if (element.value !== "Null") {
                        if (element.value.toLowerCase() === 'yes') {
                            JSONPayload[element.name] = "true"
                        } else if (element.value.toLowerCase() === 'no') {
                            JSONPayload[element.name] = "false"
                        }
                        //            JSONPayload[element.name] = element.value; 
                    }
                }
            } else if (element.type === "checkbox") {
                JSONPayload[element.name] = element.checked.toString();
            } else if (element.type === "file") {
                this.setState({
                    IsSending: true
                })
                for (let k = 0; k < element.files.length; k++) {
                    const el = element.files[k];
                    var filename = await s3Upload(el, "", true);
                    JSONPayload["temp_image_" + k] = "public/" + filename;
                }
            } else if (element.type !== "submit") {
                if (element.value) {
                    JSONPayload[element.name] = element.value;
                }
            }
        }
        this.setState({
            IsSending: true
        })
        if (this.state.formcallback) {
            //console.log(this.state.formcallback, JSONPayload);
            var r = await axios.post(this.state.formcallback, JSONPayload)
                .then(() => {
                    console.log(r);
                })
        }
        else if (this.state.formname === this.state.FormNotFound) {
            this.TrySTH(JSONPayload, "errorform", this.PayloadCallback)
        } else {
            this.TrySTH(JSONPayload, "sgf", this.PayloadCallback)
        }
    }

    PayloadCallback(self, response) {
        var err = false;
        if (response.data.responsewas.indexOf("ERROR") > -1) {
            err = response.data.responsewas.replace("<IMPORT><ERROR>", "").replace("</ERROR></IMPORT>", "")
        }
        self.setState({
            errorState: err,
            IsSent: true,
            IsSending: false
        });
        //if (response)
    }

    handleFormSubmit = async event => {
        event.preventDefault();
        //console.log(event.target);
    }
    //unused, but should revisit//

    GetObject(d, i) {
        if (this.props.match.params.add) {
            if ((this.props.match.params.add.toLowerCase().indexOf("l") > -1) &&
                d.ID.indexOf("AddressName") > -1) {
                return <FORM.PinDrop Data={d} />
                //return <span className="error-text">swap out pin drop control?</span>
            }
        }

        switch (d.Type) {
            case "ReadOnly":
                return <FORM.ReadOnly Data={d} />
            case "TextBox":
                return <FORM.TextInput Data={d} />
            case "ComboBoxInput":
                return <FORM.ComboInput Data={d} />
            case "ImageBase64":
                return <FORM.MultiImagesBase64 Data={d} />
            case "Image":
                return <FORM.MultiImages Data={d} />
            case "MultiLineTextBox":
                return <FORM.TextareaInput Data={d} />
            case "Date":
                return <FORM.DateInput Data={d} />
            case "Time":
                return <FORM.TimeInput Data={d} />
            case "CheckBox":
                if (d.DataType === "YesNo") {
                    return <FORM.Checkbox Data={d} />
                }
                return "multi"
            case "Price":
                return <input type="number" />
            case "NullCheckBox":
                d.Values = ["Yes", "No"];
                d.Default = "";
                d.IsNullable = true;
                return <FORM.ButtonToggle Data={d} />
            case "YesNo":
                d.Values = ["Yes", "No"]
                d.IsNullable = false;
                return <FORM.ButtonToggle Data={d} />
            case "ComboBox":
                //return <FORM.ComboInput Data={d} />
                return <FORM.ComboBox Data={d} />
            default:
                return <span className="error-text">type doesn't exist</span>
        }

    }

    returnFormContent() {
        return (
            <Fragment>
                <form className="public-form" onSubmit={this.handleSubmit}>
                    <div className="form-wrapper">
                        <div className="form-content">
                            {
                                this.state.formcontent.map((d, i) => {
                                    return (
                                        <div key={"group_" + i} className="group" >
                                            <h3>{d.Name}</h3>
                                            <div className="field-list">
                                                {d.Elements.map((k, i) => {
                                                    return <div className="form-div"
                                                        key={"f_" + i + "_" + k}
                                                        attr-title="TEST">
                                                        {k.DataType === "YesNo"
                                                            ? null
                                                            : <h4>{k.Label}</h4>}
                                                        {this.GetObject(k, i)}
                                                    </div>
                                                })}
                                                <div style={{ clear: "both" }}></div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="form-button-wrapper">
                        <button type="submit" className="submit-button" style={{ backgroundColor: this.state.formdark }}>Submit</button>
                    </div>
                </form>
                {
                    this.state.errorState
                        ? <div className="form-block">
                            <div className="dialogueBoxRounded thank-you error">
                                <h2>Error with your submission</h2>
                                <p>{this.state.errorState}</p>
                                <button className="back-button" onClick={() => this.backToForm()}>
                                    Take me back!
            </button>
                            </div>
                        </div>
                        : null
                }
                {
                    this.state.IsSending
                        ? <div className="form-block">
                            {this.returnFormLoader()}
                        </div>
                        : null
                }
            </Fragment>
        )
    }
    returnFormLoader() {
        return (
            <div className="form-loader">
                {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((d, i) => {
                    return (
                        <div className="form-loader-box" key={"loader_" + i}>
                            <div className="inner-element"></div>
                        </div>
                    )
                })}
            </div>
        )
    }
    cleanImage(st) {
        if (st.indexOf("http") > -1) {
            return st;
        }
        return "/Images/public-logos/" + st;
    }
    backToForm() {
        this.setState({
            errorState: null,
            IsSent: false
        })
    }
    renderSent() {
        return (
            <div className="thank-you">
                <h2>Thank you.</h2>
                <p>Your form has been submitted.</p>
                <p>{/* response */}</p>
            </div>
        )
    }
    fixFormTitle(st) {
        switch (st) {
            case "reportafakeform":
                return "Report a Fake";
            case "newauthorizationform":
                return "New Authorization"
            default:
                return st;
        }
    }

    renderForm() {
        return (
            <Fragment>
                <div className="imgHolder">
                    <div className="form-logo">
                        {this.state.formimage
                            ? <img src={this.cleanImage(this.state.formimage)} alt={this.state.formcaption} />
                            : null}
                        <h1> {
                            this.state.formcaption
                                ? this.state.formcaption
                                : this.fixFormTitle(this.state.formname)} </h1>
                    </div>
                </div>
                {!this.state.formcontent
                    ? this.returnFormLoader()
                    : this.state.IsSent && !this.state.errorState
                        ? this.renderSent()
                        : this.returnFormContent()}
            </Fragment>
        );
    }

    render() {
        return (
            <div className="Signup AuthForm DialogueBoxRounded">
                { this.renderForm()}
            </div>
        );
    }
}