import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import "./Home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ErrorMessage: "URL needs to point to a form",
      isLoading: true,
      notes: [],
      invitations: {Targets:[]}
    };
  }




  renderLander() {
    return (
      <Fragment>
      <div className="lander">
        <h1 className="HomeText">The AI powered field app for investigators.</h1>
      </div>
      <br/>
      <Link to="/signup" className="btn btn-success btn-lg btn-xxl">
            Try it now!
      </Link>
      </Fragment>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.state.ErrorMessage?<h4 className="error-message">{this.state.ErrorMessage}</h4>:null}
      </div>
    );
  }
}