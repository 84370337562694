import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {  Navbar } from "react-bootstrap";
import "./App.css";
import Routes from "./Routes";

class App extends Component {
  constructor(props) {
    super(props);
    //set theme//
    document.querySelector('body').setAttribute("class", this.QS('thm'));
  }
  QS(term) {
    var st = this.props.location.search;
    var loc = st.indexOf(term);
    if (loc < 0) {
      return null;
    }
    var newst = st.slice(loc + term.length + 1);
    var amp = newst.indexOf("&");
    if (amp > -1) {
      return newst.slice(0, amp);
    }
    return newst;
  }

  GetNavBar() {
    return (
      <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">
                <img alt="Hubstream Watcher Logo" className="SquareLogo" src="/Images/watcher.png"></img>
                <img alt="Hubstream Watcher Text Logo" className="TextLogo" src="/Images/fieldhawk_text.svg"></img>
              </Link>
            </Navbar.Brand>
          </Navbar.Header>
        </Navbar>
    )
  }

  render() {
    return (
      <div className="App container">
        {this.QS("chrome") === "false" ? <div className="NavReplacementSpacer"></div> : this.GetNavBar()}
        <Routes childProps={{}} />
      </div>
    );
  }
}

export default withRouter(App);